<template>
  <div class="flex items-stretch flex-1 h-full min-h-0 overflow-hidden">
    <main class="flex flex-col items-stretch flex-1 h-full min-h-0 overflow-hidden">
      <!-- Main Content Container -->
      <div class="flex flex-col items-stretch flex-1 h-full min-h-0 overflow-hidden">
        <!-- Toolbar: Dummy Content -->

        <toolbar
          v-if="showToolbar"
          :display-mode="displayMode"
          :layout="layout"
          :project="project"
          :active-folder="activeFolder"
          :query="query"
          :can-upload="canUpload"
          :sort-order="sortOrder"
          :info-panel-open="infoPanelOpen"
          :tree-panel-open="true"
          @layout-change="layout = $event"
          @query-change="query = $event"
          @sort-order-change="sortOrder = $event"
          @select-asset="$emit('select-asset', $event)"
          @new-folder="$emit('new-folder', $event)"
          @toggle-panel-state="onTogglePanelState"
        />

        <div class="flex items-stretch flex-1 h-full min-h-0 mt-4 overflow-hidden">
          <!-- Tree View -->
          <TreePanel
            v-if="true"
            :project="project"
            :assets="assets"
            :active-folder="activeFolder"
            :multiselected-assets="multiselectedAssets"
            :focused-asset="focusedAsset"
            :can-preview="canPreview"
            :can-select="canSelect"
            :can-replace="canReplace"
            :can-delete="canDelete"
            :can-rename="canRename"
            :dragger="dragger"
            :selected-asset="selectedAsset"
            :sort-order="sortOrder"
            :display-mode="displayMode"
            :restricted-action-types="restrictedActionTypes"
            :action-mode="actionMode"
            @multiselect-asset="$emit('multiselect-asset', $event)"
            @multideselect-asset="$emit('multideselect-asset', $event)"
            @delete-asset="$emit('delete-asset', $event)"
            @preview-asset="$emit('preview-asset', $event)"
            @rename-asset="$emit('rename-asset', $event)"
            @replace-asset="$emit('replace-asset', $event)"
            @deselect-asset="$emit('deselect-asset', $event)"
            @drag-asset="$emit('drag-asset', $event)"
            @drag-asset-focus="$emit('drag-asset-focus', $event)"
            @drop-asset="$emit('drop-asset', $event)"
            @select-asset="$emit('select-asset', $event)"
            @view-asset="$emit('view-asset', $event)"
            @move-asset="$emit('move-asset', $event)"
            @sort-order-change="sortOrder = $event"
          />

          <collection
            :project="project"
            :assets="filteredAssets"
            :active-folder="activeFolder"
            :multiselected-assets="multiselectedAssets"
            :focused-asset="focusedAsset"
            :can-preview="canPreview"
            :can-select="canSelect"
            :can-replace="canReplace"
            :can-delete="canDelete"
            :can-rename="canRename"
            :dragger="dragger"
            :layout="layout"
            :selected-asset="selectedAsset"
            :sort-order="sortOrder"
            :display-mode="displayMode"
            :restricted-action-types="restrictedActionTypes"
            :action-mode="actionMode"
            @multiselect-asset="$emit('multiselect-asset', $event)"
            @multideselect-asset="$emit('multideselect-asset', $event)"
            @delete-asset="$emit('delete-asset', $event)"
            @preview-asset="$emit('preview-asset', $event)"
            @rename-asset="$emit('rename-asset', $event)"
            @replace-asset="$emit('replace-asset', $event)"
            @deselect-asset="$emit('deselect-asset', $event)"
            @drag-asset="$emit('drag-asset', $event)"
            @drag-asset-focus="$emit('drag-asset-focus', $event)"
            @drop-asset="$emit('drop-asset', $event)"
            @select-asset="$emit('select-asset', $event)"
            @view-asset="$emit('view-asset', $event)"
            @move-asset="$emit('move-asset', $event)"
            @sort-order-change="sortOrder = $event"
          />
        </div>
      </div>

      <!-- Statusbar -->
      <!-- <div class="statusbar">

        <multiselectbar
          v-if="multiselectedAssets.length > 0"
          v-bind:project="project"
          v-bind:active-folder="activeFolder"
          v-bind:assets="filteredAssets"
          v-bind:multiselected-assets="multiselectedAssets"
          v-on:delete-asset="$emit('delete-asset', $event)"
          v-on:move-asset="$emit('move-asset', $event)"
      v-on:multiselect-reset="$emit('multiselect-reset', $event)"></multiselectbar>-->
      <!-- <statusbar
          v-if="canUpload"
          v-bind:project="project"
      v-bind:active-folder="activeFolder"></statusbar>-->

      <!-- </div> -->

      <!-- /.browser -->

      <!-- Content Tray -->
      <ContentTray />
    </main>

    <div
      v-if="infoPanelOpen"
      class="hidden p-8 overflow-y-auto bg-white border-l border-gray-200 w-96 lg:block"
    >
      <InfoPanel :multiselected-assets="multiselectedAssets" />
    </div>
  </div>
</template>

<script>
const Collection = () => import('./assets-explorer-collection.vue')
const ContentTray = () => import('./assets-explorer-tray.vue')
const TreePanel = () => import('./assets-explorer-tree-panel.vue')
const InfoPanel = () => import('./assets-explorer-info-panel.vue')

// const StatusBar = () => import('./assets-explorer-statusbar.vue')
// const MultiselectBar = () => import('./assets-explorer-multiselectbar.vue')
const Toolbar = () => import('./assets-explorer-toolbar.vue')

export default {
  components: {
    'collection': Collection,
    // 'statusbar': StatusBar,
    'toolbar': Toolbar,
    'InfoPanel': InfoPanel,
    'ContentTray': ContentTray,
    'TreePanel': TreePanel
    // 'multiselectbar': MultiselectBar,
  },

  props: {
    assets: {
      required: true,
      type: Array
    },
    activeFolder: {
      required: true,
      type: Object
    },
    canPreview: {
      required: true,
      type: Boolean
    },
    canSelect: {
      required: true,
      type: Boolean
    },
    canUpload: {
      required: true,
      type: Boolean
    },
    canReplace: {
      required: true,
      type: Boolean
    },
    displayMode: {
      required: true,
      type: String
    },
    canSeeSidebar: {
      required: true,
      type: Boolean
    },
    canDelete: {
      required: true,
      type: Boolean
    },
    canRename: {
      required: true,
      type: Boolean
    },
    dragger: {
      default: null,
      type: Object
    },
    project: {
      required: true,
      type: Object
    },
    selectedAsset: {
      default: null,
      type: Object
    },
    focusedAsset: {
      default: null,
      type: Object
    },
    multiselectedAssets: {
      required: true,
      type: Array
    },
    restrictedActionTypes: {
      type: Array,
      default: () => []
    },
    actionMode: {
      type: String,
      required: true
    }
  },

  data: function () {
    return {
      isOpen: false,
      treePanelOpen: true,
      infoPanelOpen: false,
      layout: 'list',
      query: '',
      sortOrder: 'created_at:desc'
    }
  },
  computed: {

    showToolbar() {
      return this.displayMode !== 'overview'
    },

    layoutPref() {
      return this.$store.getters['preferences/assetsLayout']
    },

    sortPref() {
      return this.$store.getters['preferences/assetsSortOrder']
    },

    treePanelOpenPref() {
      return this.$store.getters['preferences/assetsTreePanelOpen']
    },

    /**
       * The assets to display, taking into account the search query and sort
       * order.
       *
       * @return {array}
       */
    filteredAssets: function () {
      const query = this.query.toLowerCase()
      let [sortBy, sortDirection] = this.sortOrder.split(':')
      sortDirection = sortDirection.toLowerCase()

      let assets = this.assets.filter(function (asset) {
        return query === '' || asset.name.toLowerCase().includes(query)
      })

      return assets.sort(function (a, b) {
        let aValue = a[sortBy]
        let bValue = b[sortBy]
        const multiplier = (sortDirection === 'desc') ? -1 : 1

        // Ignore case when comparing values.
        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase()
          bValue = bValue.toLowerCase()
        }

        return (aValue === bValue) ? 0 : ((aValue > bValue ? 1 : -1) * multiplier)
      })
    },

    /**
       * Are there any assets?
       *
       * @return {boolean}
       */
    hasAssets: function () {
      return this.assets.length > 0
    },

    /**
       * Do any assets match the search criteria?
       *
       * @return {boolean}
       */
    hasFilteredAssets: function () {
      return this.filteredAssets.length > 0
    }
  },

  watch: {
    layout(value) {
      this.$store.dispatch('preferences/updateAssetsLayout', {
        layout: value
      })
    },

    sortOrder(value) {
      this.$store.dispatch('preferences/updateAssetsSortOrder', {
        order: value
      })
    },

    treePanelOpen(value) {
      this.$store.dispatch('preferences/updateAssetsTreePanelOpen', {
        open: value
      })
    },

    multiselectedAssets: {
      immediate: true,
      handler(value) {
        if (value.length > 0) this.infoPanelOpen = true
        else this.infoPanelOpen = false
      }
    }
  },

  mounted() {
    // Set the view + sort order based on user preferences, fallback to defaults
    if (this.layoutPref !== null) this.layout = this.layoutPref
    if (this.sortPref !== null) this.sortOrder = this.sortPref
    if (this.treePanelOpenPref !== null) this.treePanelOpen = this.treePanelOpenPref

    if (this.displayMode === 'mini') this.layout = 'list' // List only in mini mode
  },

  methods: {
    onTogglePanelState(panel) {
      if (panel === 'info') {
        this.infoPanelOpen = !this.infoPanelOpen
      }

      if (panel === 'tree') {
        this.treePanelOpen = !this.treePanelOpen
      }
    }
  }
}

</script>
